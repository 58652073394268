import { Event } from "./models/Event";

export const getAvailableSlots = (date: Date, events: Event[]): number => {
  // Set the desired working hours (8am to 6pm)
  const workingHoursStart = new Date(date);
  workingHoursStart.setHours(8, 0, 0, 0);

  const workingHoursEnd = new Date(date);
  workingHoursEnd.setHours(18, 0, 0, 0);

  const filteredEvents = events
    .filter(event => event.StartTime >= workingHoursStart && event.EndTime <= workingHoursEnd)
  // Sort events by their start times
  filteredEvents
    .sort((a, b) => a.StartTime.getTime() - b.StartTime.getTime());

  let availableTime = 0;
  let currentTime = new Date(workingHoursStart);

  for (const event of filteredEvents) {
    if (event.StartTime > currentTime) {
      // If there is a gap between the current time and the next event, add it to available time
      availableTime += (event.StartTime.getTime() - currentTime.getTime()) / 3600000; // 3600000 ms in an hour
    }

    // Update current time to the end time of the event
    currentTime = event.EndTime > currentTime ? event.EndTime : currentTime;
  }

  // Check if there is any remaining time after the last event
  if (currentTime < workingHoursEnd) {
    availableTime += (workingHoursEnd.getTime() - currentTime.getTime()) / 3600000;
  }

  return availableTime;
}

export const toHoursString = (hours: number): string => {
  const wholeHours = Math.floor(hours);
  const minutes = Math.round((hours - wholeHours) * 60);

  if (wholeHours === 0 && minutes === 0) {
    return '0h';
  }

  let result = '';

  if (wholeHours > 0) {
    result += `${wholeHours}h`;
  }

  if (minutes > 0) {
    if (result.length > 0) {
      result += ' ';
    }
    result += `${minutes}m`;
  }

  return result;
}
