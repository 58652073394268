export const portuguese = {
  "pt": {
    "schedule": {
      "day": "Dia",
      "week": "Semana",
      "workWeek": "Semana útil",
      "month": "Mês",
      "agenda": "Agenda",
      "weekAgenda": "Agenda Semanal",
      "workWeekAgenda": "Work Week Agenda",
      "monthAgenda": "Mensal Agenda",
      "today": "Hoje",
      "noEvents": "Sem eventos",
      "emptyContainer": "Não há eventos para este dia.",
      "allDay": "Dia inteiro",
      "start": "Início",
      "end": "Fim",
      "more": "mais",
      "close": "Fechar",
      "cancel": "Cancelar",
      "noTitle": "(Sem Título)",
      "delete": "Excluir",
      "deleteEvent": "Excluir Evento",
      "deleteMultipleEvent": "Excluir Vários Eventos",
      "selectedItems": "Itens selecionados",
      "deleteSeries": "Delete Series",
      "edit": "Editar",
      "editSeries": "Edit Series",
      "editEvent": "Editar Evento",
      "createEvent": "Criar",
      "subject": "Assunto",
      "addTitle": "Adicionar título",
      "moreDetails": "Mais detalhes",
      "save": "Salvar",
      "editContent": "Do you want to edit only this event or entire series?",
      "deleteRecurrenceContent": "Do you want to delete only this event or entire series?",
      "deleteContent": "Você tem certeza de que quer excluir este evento?",
      "deleteMultipleContent": "Are you sure you want to delete the selected events?",
      "newEvent": "Novo evento",
      "title": "Título",
      "location": "Local",
      "description": "Descrição",
      "timezone": "Fuso horário",
      "startTimezone": "Fuso do início",
      "endTimezone": "Fuso do fim",
      "repeat": "Repetir",
      "saveButton": "Salvar",
      "cancelButton": "Cancelar",
      "deleteButton": "Excluir",
      "recurrence": "Recorrente",
      "wrongPattern": "The recurrence pattern is not valid.",
      "seriesChangeAlert": "The changes made to specific instances of this series will be cancelled and those events will match the series again.",
      "createError": "The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.",
      "recurrenceDateValidation": "Some months have fewer than the selected date. For these months, the occurrence will fall on the last date of the month.",
      "sameDayAlert": "Two occurrences of the same event cannot occur on the same day.",
      "editRecurrence": "Edit Recurrence",
      "repeats": "Repeats",
      "alert": "Alerta",
      "startEndError": "A data final sleecionada é anterior à data inicial.",
      "invalidDateError": "A data inserida é inválida.",
      "ok": "Ok",
      "occurrence": "Ocorrência",
      "series": "Series",
      "previous": "Anterior",
      "next": "Próximo",
      "timelineDay": "Timeline Day",
      "timelineWeek": "Timeline Week",
      "timelineWorkWeek": "Timeline Work Week",
      "timelineMonth": "Timeline Month",
      "expandAllDaySection": "Expand",
      "collapseAllDaySection": "Collapse"
    },
    "recurrenceeditor": {
      "none": "None",
      "daily": "Daily",
      "weekly": "Weekly",
      "monthly": "Monthly",
      "month": "Month",
      "yearly": "Yearly",
      "never": "Never",
      "until": "Until",
      "count": "Count",
      "first": "First",
      "second": "Second",
      "third": "Third",
      "fourth": "Fourth",
      "last": "Last",
      "repeat": "Repeat",
      "repeatEvery": "Repeat Every",
      "on": "Repeat On",
      "end": "End",
      "onDay": "Dia",
      "days": "Dia(s)",
      "weeks": "Semana(s)",
      "months": "Mês(es)",
      "years": "Ano(s)",
      "every": "every",
      "summaryTimes": "time(s)",
      "summaryOn": "on",
      "summaryUntil": "até",
      "summaryRepeat": "repete",
      "summaryDay": "dia(s)",
      "summaryWeek": "semana(s)",
      "summaryMonth": "mês(s)",
      "summaryYear": "ano(s)"
    }
  }
}
