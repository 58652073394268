import { Event } from "../models/Event"
import { Patient } from "../models/Patient"

const buildProfessionalsDict = async () => {
  const result = await fetch('https://status.institutoneuron.com.br/professionals.php', {
    method: 'POST',
  })
  const responseJson = await result.json()
  const dict: Record<string, any> = {}
  responseJson.content.forEach((professional: any) => {
    dict[professional['profissional_id']] = professional
  })
  return dict
}

export const fetchPatient = async (patientId: number) => {
  let patient: Patient = {
    name: patientId.toString()
  }
  try {
    const result = await fetch('https://status.institutoneuron.com.br/patients.php', {
      method: 'POST',
      body: JSON.stringify({ paciente_id: patientId })
    })
    const res = await result.json()
    patient = {
      name: res.content.nome
    }
  } catch (e) {
    console.error(e)
  }
  return patient
}

export const fetchFeegowEvents = async (selectedDate: Date) => {
  const professionalsDict = await buildProfessionalsDict()
  const dateToday = new Date(selectedDate)
  let firstDayOfTheWeek = new Date(dateToday.setDate(dateToday.getDate() - dateToday.getDay()));
  let lastDayOfTheWeek = new Date(dateToday.setDate(dateToday.getDate() - dateToday.getDay() + 6));
  const firstDateString = `${firstDayOfTheWeek.getDate()}-${firstDayOfTheWeek.getMonth() + 1}-${firstDayOfTheWeek.getFullYear()}`
  const lastDateString = `${lastDayOfTheWeek.getDate()}-${lastDayOfTheWeek.getMonth() + 1}-${lastDayOfTheWeek.getFullYear()}`
  const body = { first: firstDateString, last: lastDateString }
  const result = await fetch('https://status.institutoneuron.com.br/index.php', {
    method: 'POST',
    body: JSON.stringify(body)
  })
  const responseJson = await result.json()
  const feegowEvents: Event[] = responseJson.content.map((event: any) => {
    const dateArray = event.data.split('-')
    const startDate = new Date(`${dateArray[1]}-${dateArray[0]}-${dateArray[2]}`.replace(/-/g, "/"))
    const horario = event.horario.split(':')
    startDate.setHours(horario[0], horario[1], horario[2])
    const endDate = new Date(startDate.toString())
    const statusId = event.status_id
    if (event.duracao > 0) {
      endDate.setHours(endDate.getHours(), endDate.getMinutes() + event.duracao)
    } else {
      endDate.setHours(endDate.getHours(), endDate.getMinutes() + 30)
    }
    return {
      Subject: professionalsDict[event.profissional_id]?.nome ?? 'Indefinido',
      StartTime: startDate,
      EndTime: endDate,
      roomId: event.local_id ?? 0,
      patientId: event.paciente_id ?? 0,
      cancelled: statusId === 11 || statusId === 16,
      done: statusId === 3 || statusId === 6,
    } as Event
  })
  return feegowEvents.filter(event => !event.cancelled && !event.done)
}