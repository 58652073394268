import { toHoursString } from "../utils"
import Room from "./Room"
import { Typography } from 'antd'
import { CSSProperties } from "react"

const availableColor = '#b7eb8f'
const unavailableColor = '#ffbb96'

interface ClickableRoomProps {
  label: string
  availableHours: number
  height: number
  onClick: () => void
  style?: CSSProperties
  hideTitle?: boolean
  showAvailable?: boolean
}

const ClickableRoom: React.FC<ClickableRoomProps> = ({
  label,
  availableHours,
  height,
  onClick,
  style,
  hideTitle,
  showAvailable
}) => {
  return <Room
    backgroundColor={availableHours === 0 ? unavailableColor : availableColor}
    height={`${height}%`}
    onClick={onClick}
    style={style}
  >
    {!hideTitle && <Typography style={{ textAlign: 'center' }}>{label}</Typography>}
    {!hideTitle && showAvailable && <Typography style={{ textAlign: 'center' }}>({toHoursString(availableHours)} disponíveis)</Typography>}
  </Room>
}

export default ClickableRoom
