import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-calendars/styles/material.css";
import "@syncfusion/ej2-dropdowns/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-splitbuttons/styles/material.css";
import "@syncfusion/ej2-react-schedule/styles/material.css";
import * as React from 'react';
import { useEffect, useRef, useState } from 'react'
import { Typography } from 'antd';
import { ScheduleComponent, Day, Week, Inject } from '@syncfusion/ej2-react-schedule';
import { Internationalization, loadCldr, L10n } from '@syncfusion/ej2-base';
import './rooms.css'
import { getAvailableSlots } from '../utils';
import ClickableRoom from './ClickableRoom';
import Room from './Room';
import { Patient } from '../models/Patient';
import { fetchFeegowEvents, fetchPatient } from '../api/feegow';
import { portuguese } from '../localization';
import { Event } from "../models/Event"

const firstColor = '#EDEDED'
const secondColor = '#D9D9D9'

interface PatientNameProps {
  event: Event
}

const PatientName: React.FC<PatientNameProps> = ({ event }) => {
  const [patient, setPatient] = useState<Patient>()
  useEffect(() => {
    fetchPatient(event.patientId).then(result => {
      setPatient(result)
    })
  }, [])
  return <h3 style={{ color: '#fff' }}>{patient?.name ?? '...'}</h3>
}

interface RoomInfo {
  id: number
  availableHours: number
  weekEvents: Event[]
  label: string
}


const defaultRooms: Record<string, RoomInfo> = {
  sala1: {
    id: 1,
    availableHours: 10,
    weekEvents: [],
    label: 'Sala 1',
  },
  sala2: {
    id: 2,
    availableHours: 10,
    weekEvents: [],
    label: 'Sala 2',
  },
  sala3: {
    id: 3,
    availableHours: 10,
    weekEvents: [],
    label: 'Sala 3',
  },
  sala4: {
    id: 4,
    availableHours: 10,
    weekEvents: [],
    label: 'Sala 4',
  },
  sala5: {
    id: 7,
    availableHours: 10,
    weekEvents: [],
    label: 'Sala 5',
  },
  sala6: {
    id: 5,
    availableHours: 10,
    weekEvents: [],
    label: 'Sala 6',
  },
  sala_reabilitacao: {
    id: 6,
    availableHours: 10,
    weekEvents: [],
    label: 'Sala de Reabilitação',
  }
}

const Rooms: React.FC = () => {
  const [selectedRoom, setSelectedRoom] = useState<string>('sala1')
  const [rooms, setRooms] = useState<Record<string, RoomInfo>>(defaultRooms)
  const [showAvailable, setShowAvailable] = useState<boolean>(false)
  const scheduleObj = useRef<any>(null)
  const instance = new Internationalization()
  const scale = 25

  loadCldr(
    require('cldr-data/supplemental/numberingSystems.json'),
    require('cldr-data/main/pt/ca-gregorian.json'),
    require('cldr-data/main/pt/numbers.json'),
    require('cldr-data/main/pt/timeZoneNames.json')
  )

  L10n.load(portuguese)

  const onActionComplete = (args: any) => {
    if (
      args.requestType === 'dateNavigate' ||
      args.requestType === 'toolBarItemRendered' ||
      args.requestType === 'viewNavigate'
    ) {
      const currentView = scheduleObj?.current?.currentView
      setShowAvailable(currentView === 'Day')
      const selectedDate = scheduleObj?.current?.selectedDate
      const getEvents = async () => {
        const roomsCopy = { ...rooms }
        if (selectedDate) {
          const events = await fetchFeegowEvents(selectedDate)
          for (const roomCopy of Object.values(roomsCopy)) {
            const filteredEvents = events.filter(event => event.roomId === roomCopy.id)
            roomCopy.weekEvents = filteredEvents
            roomCopy.availableHours = getAvailableSlots(selectedDate, filteredEvents)
          }
        }
        setRooms(roomsCopy)
      }
      getEvents()
    }
    return
  }

  const quickInfoTemplates = {
    header: (event: Event) => {
      return <div style={{ padding: 16 }}>
        <h2 style={{ color: '#fff' }}>{event.Subject}</h2>
        <PatientName event={event} />
      </div>
    },
  }

  const onCreated = () => {
    if (scheduleObj != null) {
      scheduleObj.current?.scrollTo(instance.formatDate(new Date(), { skeleton: 'hm' }));
    }
  }

  const workHours = {
    highlight: true, start: '08:00', end: '18:00'
  };

  const eventSettings = { dataSource: rooms[selectedRoom].weekEvents }

  return (<div style={{
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: window.innerWidth > 626 ? 'row' : 'column',
    backgroundColor: '#fff'
  }}>
    <div style={{
      width: '35vw',
    }}>
      <div style={{
        width: `${scale}vw`,
        display: `flex`,
        height: `calc(1.838 * ${scale}vw)`,
        marginRight: 'auto',
        marginLeft: 'auto',
        minHeight: '542px',
        minWidth: '295px'
      }}>
        <div style={{ width: '40%' }}>
          <ClickableRoom
            label="Sala 4"
            availableHours={rooms['sala4'].availableHours}
            height={23.5}
            onClick={() => setSelectedRoom('sala4')}
            showAvailable={showAvailable}
          />
          <ClickableRoom
            label="Sala 5"
            availableHours={rooms['sala5'].availableHours}
            height={16.4}
            style={{ borderTop: 'none' }}
            onClick={() => setSelectedRoom('sala5')}
            showAvailable={showAvailable}
          />
          <ClickableRoom
            label="Sala 6"
            availableHours={rooms['sala6'].availableHours}
            height={16.4}
            style={{ borderTop: 'none' }}
            onClick={() => setSelectedRoom('sala6')}
            showAvailable={showAvailable}
          />
          <ClickableRoom
            label="Sala de Reabilitação"
            availableHours={rooms['sala_reabilitacao'].availableHours}
            height={25.4}
            style={{ borderTop: 'none', borderBottom: 'none' }}
            onClick={() => setSelectedRoom('sala_reabilitacao')}
            hideTitle
            showAvailable={showAvailable}
          />
          <ClickableRoom
            label="Sala de Reabilitação"
            availableHours={rooms['sala_reabilitacao'].availableHours}
            height={18.3}
            style={{ borderTop: 'none', borderRight: 'none', justifyContent: 'flex-start' }}
            onClick={() => setSelectedRoom('sala_reabilitacao')}
            showAvailable={showAvailable}
          />
        </div>
        <div style={{ width: '14%', justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
          <ClickableRoom
            label="Sala 3"
            availableHours={rooms['sala3'].availableHours}
            height={18.3}
            style={{ borderRight: 'none', borderLeft: 'none' }}
            onClick={() => setSelectedRoom('sala3')}
            hideTitle
          />
          <ClickableRoom
            label="Sala de Reabilitação"
            availableHours={rooms['sala_reabilitacao'].availableHours}
            height={18.3}
            style={{ borderLeft: 'none' }}
            onClick={() => setSelectedRoom('sala_reabilitacao')}
            hideTitle
          />
        </div>
        <div style={{ width: '46%' }}>
          <ClickableRoom
            label="Sala 3"
            availableHours={rooms['sala3'].availableHours}
            height={18.3}
            style={{ borderLeft: 'none' }}
            onClick={() => setSelectedRoom('sala3')}
            showAvailable={showAvailable}
          />
          <Room
            backgroundColor={secondColor}
            height={'13%'}
            style={{ borderTop: 'none' }}
          />
          <Room
            backgroundColor={firstColor}
            height={'13%'}
            style={{ borderTop: 'none' }}
          />
          <ClickableRoom
            label="Sala 2"
            availableHours={rooms['sala2'].availableHours}
            height={11.7}
            style={{ borderTop: 'none' }}
            onClick={() => setSelectedRoom('sala2')}
            showAvailable={showAvailable}
          />
          <ClickableRoom
            label="Sala 1"
            availableHours={rooms['sala1'].availableHours}
            height={11.7}
            style={{ borderTop: 'none' }}
            onClick={() => setSelectedRoom('sala1')}
            showAvailable={showAvailable}
          />
        </div>
      </div>
    </div>
    {/* <Table columns={columns} dataSource={tableData} pagination={false} /> */}
    <div
      style={{
        width: '65vw',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'scroll',
      }}
    >
      <Typography.Title style={{ marginTop: 32 }}>
        {rooms[selectedRoom].label}
      </Typography.Title>
      {typeof window !== 'undefined' ? <ScheduleComponent
        ref={scheduleObj}
        style={{
          overflow: 'scroll',
        }}
        height='100%'
        quickInfoTemplates={quickInfoTemplates}
        actionComplete={onActionComplete}
        currentView='Day'
        eventSettings={eventSettings}
        workHours={workHours}
        startHour='08:00'
        endHour='18:00'
        created={onCreated}
        locale='pt'
        views={['Day', 'Week']}
      >
        {/*// @ts-ignore */}
        <Inject
          services={[
            Day,
            Week,
            // WorkWeek,
            // Month,
            // Agenda
          ]}
        />
      </ScheduleComponent> : <></>}
    </div>
  </div >)
}

export default Rooms
