import styled from '@emotion/styled'

interface RoomProps {
  backgroundColor: string
  height: string
}

const Room = styled.div<RoomProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #888;
  width: 100%;
  background-color: ${props => props.backgroundColor};
  height: ${props => props.height};
`

export default Room
