import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Rooms from './components/Rooms';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('ORg4AjUWIQA/Gnt2VlhhQlJCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn9SdkNjXnpfcHRWT2lZ');


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Rooms />
)
